import React from 'react';

function FaqCard({title, content}) {
  return (
    <div className="bg-white rounded-2xl transition-shadow duration-300 shadow-lg hover:shadow-xl px-8 py-6 grid gap-4 h-full">
      <span className="text-xl font-bold">{title}</span>
      {content}
    </div>
  );
}

export default FaqCard;