import React from 'react';
import Layout from "../../components/layouts"
import Container from "../../components/Container"
import FaqCard from '../../components/FaqCard';
import { graphql } from 'gatsby';
import ButtonLink from '../../components/ButtonLink';
import { Helmet } from 'react-helmet';

function index({data}) {
  return (
    <>
    <Helmet>
      <title>Classify — Support</title>
      <meta name="description" content="Find help with the app, its features and the company itself." />
      <link rel="canonical" href="https://classify.org.uk/support" />

      <meta property="og:title" content="Classify — Support"/>
      <meta property="og:url" content="https://classify.org.uk/support"/>
      <meta property="og:image" content="https://i.imgur.com/ODTJzzm.png"/>
      <meta property="og:description" content="Find help with the app, its features and the company itself."/>
      <meta property="og:type" content="website"/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:site" content="@classifyapp"/>
      <meta property="twitter:title" content="Classify — Support"/>
      <meta name="twitter:image" content="https://i.imgur.com/ODTJzzm.png"/>
      <meta property="twitter:description" content="Find help with the app, its features and the company itself."/>
    </Helmet>
    <Layout>
      <section id="faq" className="bg-support">
        <Container no-vertical>
          <div className="grid gap-12 text-center py-12 lg:py-24">
            <h1>🧐</h1>
            <p className="text-xl">
              Find help with the app, its features and the company itself.
            </p>
            <div className="flex flex-wrap justify-center w-full">
              {data.allWpFaq.nodes.map((faq, index) => <div className="w-full lg:w-1/2 p-4" key={index}><FaqCard title={faq.title} content={faq.faqContent.content} /></div>)}
            </div>
            <div>
              <h2 className="pb-12">Still have questions?</h2>
              <ButtonLink to="/support/contact/" state={{referrer: 'Support'}}>Contact us</ButtonLink>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
    </>
  );
}

export const pageQuery = graphql`
  query {
    allWpFaq {
      nodes {
        title
        faqContent {
          content
        }
      }
    }
  }
`

export default index;